 <template>
	<el-dialog top="1vh" :title="title" width="1600px" :visible.sync="this_is_show">
		<el-table :data="need_pay_list" :border="true" style="width: 100%">
			<el-table-column label="运单编号/总运费">
				<template slot-scope="scope">
					<div>{{scope.row.truck_tord_num}}</div>
					<div>总运费:{{scope.row.freight_total}}元</div>
				</template>
			</el-table-column>
			<el-table-column label="发/到货地">
				<template slot-scope="scope">
					<div>{{scope.row.case_addr}}</div>
					<div>{{scope.row.aim_addr}}</div>
				</template>
			</el-table-column>
			<el-table-column label="发/到货时间" width="180px">
				<template slot-scope="scope">
					<div>{{scope.row.start_time_text}}</div>
					<div>{{scope.row.arrived_time_text}}</div>
				</template>
			</el-table-column>
			<el-table-column label="收款人/司机/车辆" width="200px">
				<template slot-scope="scope">
					<!-- <div style="display: flex;margin-bottom: 20px;"><div class="payee red"><div class="shou">收</div></div><div class="data">{{scope.row.truck_owner}}</div></div> -->
					<div style="display: flex;"><div class="data">{{scope.row.driver}}/{{scope.row.truck}}</div></div>
				</template>
			</el-table-column>
			<el-table-column label="剩余可支付运费" width="125px">
				<template slot-scope="scope">
					<div>{{scope.row.this_pay_freight}} 元
						<!-- /服务费:{{scope.row.this_service_charge}}</div>
					<div>共计:{{scope.row.this_shipper_need_pay}} -->

					</div>
				</template>
			</el-table-column>
			<el-table-column width="150px">
				<template slot="header" slot-scope="scope" >
					<div style="display:flex;font-size: 18px;">  <span class="green"  style="border-radius: 5px;color:black;padding: 3px 3px;">司机收款</span> <span  v-if="driver_pay_total_ishow" @click="driver_money_empty('empty')" style="font-size: 12px;" class="operation">清空</span>
					<span v-else @click="driver_money_empty('max')" class="operation">满额</span></div>
					<div @click="driver_custom('司机')" >批量修改</div>
					
				</template>
				<template slot-scope="scope">
					<div style="display:flex;">
						<el-input  v-model="scope.row.shipper_pay_much_driver" placeholder="给司机"  @input="this_pay_driver_input(scope.row)"  type="number"></el-input>
					</div>
				</template>
			</el-table-column>
			<!-- <el-table-column width="150px">
				<template slot="header" slot-scope="scope" >
					<div style="display:flex;font-size: 18px;"> <span class="red" style="border-radius: 5px;color:black;padding: 3px 3px;">收款人收款</span>  <span v-if="truck_owner_pay_total_ishow" style="font-size: 12px;" @click="truck_owner_money_empty('empty')" class="operation">清空</span>
					<span v-else @click="truck_owner_money_empty('max')" class="operation">满额</span></div>
					<div @click="driver_custom('收款人')" >批量修改</div>
				</template>`
				<template slot-scope="scope">
					<div style="display:flex;">
						<el-input  v-model="scope.row.shipper_pay_much_truck_owner" placeholder="给收款人"  @input="this_pay_truck_owner_input(scope.row)"  type="number"></el-input>
					</div>
				</template>
			</el-table-column> -->
			<el-table-column label="保障服务费" v-if="class_hide">
				<template slot-scope="scope" >
				   <el-input v-if="scope.row.system_profit>0" @input="vule_max(scope.row)" v-model="scope.row.shipper_pay_system_profit" placeholder="保障服务费" ></el-input>
				</template>
			</el-table-column>
			<el-table-column label="服务费">
				<template slot-scope="scope">
				   <el-input v-model="scope.row.this_pay_service_charge" placeholder="服务费" disabled></el-input>
				</template>
			</el-table-column>
			<el-table-column label="支付类型" width="300px">
				<template slot-scope="scope">
					<div style="display:flex;">
						
						<!-- <el-input v-model="scope.row.shipper_pay_much" :max="scope.row.this_pay_freight" @change="get_this_pay_info(scope.row)" style="margin-left:5px;width:100px" type="number"></el-input> -->
						<!-- <el-input v-model="scope.row.shipper_pay_much_driver" placeholder="给司机" :max="scope.row.this_pay_freight_driver_max" @change="this_pay_driver_input(scope.row)" style="margin-left:5px;width:100px" type="number"></el-input>
						<el-input v-model="scope.row.shipper_pay_much_truck_owner" placeholder="给收款人" :max="scope.row.this_pay_freight_truck_owner_max" @change="this_pay_truck_owner_input(scope.row)" style="margin-left:5px;width:100px" type="number"></el-input> -->
						<el-select v-model="scope.row.this_pay_type" style="margin-left:5px;width:110px" @change="get_this_pay_info(scope.row)">
							<el-option label="现金" value="1"></el-option>
							<el-option label="燃油费" value="2"></el-option>
							<el-option label="过路费" value="3"></el-option>
						</el-select>
						<el-select v-model="scope.row.mark" style="margin-left:5px;width:237px" placeholder="支付备注" allow-create filterable>
							<el-option label="预付款" value="预付款"></el-option>
							<el-option label="到货付" value="到货付"></el-option>
							<el-option label="回单付" value="回单付"></el-option>
							<el-option label="其他" value="其他"></el-option>
						</el-select>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="under_tab">
			<div class="this_pay_total">本次合计:{{this_pay_total}}元 总运费:{{shipper_pay_much}}元 总服务费:{{this_pay_service_charge}}元<span style="magrin-left:10px"> 总条数:{{max}}条</span></div>
		</div>

		<el-form label-width="97px" style="padding-top:0;margin-top:30px">

			<div class="tab1_inner">
				<el-form-item label="操作类型" style="width:40%">
					<el-select class="el_inner_width" v-model="this_act_type">
						<el-option label="支付" value="1"></el-option>
						<el-option label="请款" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付类型" style="width:40%" v-if="pay_service_status ==1">
					<el-select class="el_inner_width" v-model="pay_service_type">
						<el-option label="全额支付" value="1"></el-option>
						<el-option label="只支付运费" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付密码" style="width:60%">
					<el-input class="el_inner_width" v-model="pay_pwd" type="password" :disabled="this_act_type==2" clearable>
						<el-button type="success" @click="reset_pay_pwd_open" slot="append">
							<i class="el-icon-edit"></i>
						</el-button>
					</el-input>
				</el-form-item>
			</div>

			<div style="text-align:center">
				<el-button type="primary" @click="sub(0)">提交</el-button>
				<!-- <el-button type="primary" @click="sub1">批量提交</el-button> -->
			</div>
		</el-form>

		<!-- 重置支付密码 -->
		<el-dialog
			top="3vh" width="500px" 
			title="重置支付密码"
			append-to-body 
			custom-class="el-dialog-s" 
			:visible.sync="reset_pay_pwd_is_show"
		>
			<payPwdReset @done="reset_pay_pwd_close"></payPwdReset>
		</el-dialog>

	</el-dialog>
</template>

<script>
	import payPwdReset from '@/pages/user/act/pay_pwd.vue'
	import { Loading } from 'element-ui';
	import {mapState} from 'vuex'
	export default {
		props:{
			tord_list:Array,
			is_show:Number,
			num:Number,
			title:String,
			pay_service_status:[Number,null]
		},
		components:{
			payPwdReset
		},
		data() {
			return {
				driver_state:0,//司机状态码
				truck_owner_state:0,//车队长状态码
				profit_state:0,//公司利润状态码
				service_state:0,//服务费状态码
				driver_pay_total_ishow:true,
				truck_owner_pay_total_ishow:true,
				//重置支付密码页面是否显示
				reset_pay_pwd_is_show:false,

				//页面是否显示
				this_is_show:false,

				//本次支付金额
				this_pay_total:0,
				shipper_pay_much:0, //总运费
				this_pay_service_charge:0,//服务费
				//本次支付列表
				need_pay_list:[],

				//操作类型(1:支付,2:请款)
				this_act_type:'1',

				//支付密码
				pay_pwd:'',

				//备注
				pay_mark:'',

				max:'', //总条数

				class_hide:false,

				pay_service_type:'',//支付类型

				fail_pay_list:[],//支付操作失败数据
				is_show_tel:['18915059081','18056918989','18864512345','19190503399','13453497870','13834349811','13333574703','13388120088','18161305021','15983823418','13118110801','13980112099','18111500986','15183803894','13508000881','18227595781','13981027972',],
				nums:1,
			}
		},
		watch:{
			is_show(new_data){
				if(this.is_show_tel.indexOf(this.user_info.tel)!=-1){
					this.class_hide =true
				}
				if(this.title=="批量请款" || this.title=="请款"){
					this.this_act_type ='2'
				}else{
					this.this_act_type ='1'
				}
				if(new_data==0){
					this.this_is_show=false
				}else {

					//清空支付密码和备注
					this.need_pay_list=[];
					this.pay_pwd=""
					this.pay_mark=""

					//计算本次需付
					this.this_payed_calc();
				}
			},
		},
		computed:{
			...mapState(["user_info"])
		},
		methods: {
			
			vule_max(e){
				
			  if( e.shipper_pay_system_profit >50){
				  e.shipper_pay_system_profit  = 50
			  }
			  
			  //只能 0与 e.system_profit  选一个值
			  if(e.shipper_pay_system_profit>0){
				  e.shipper_pay_system_profit=e.system_profit;
			  }else{
				  e.shipper_pay_system_profit=0;
			  }
			  
			  //自动计算服务费
			  let t1=parseFloat(e.shipper_pay_much_driver)+parseFloat(e.shipper_pay_much_truck_owner);
			  let t2=parseFloat(e.this_pay_freight_driver_max)+parseFloat(e.this_pay_freight_truck_max);
			  
			  if(Number(e.shipper_pay_much_driver)+Number(e.shipper_pay_much_truck_owner)+Number(e.system_profit)>e.this_pay_freight){
			  	//lvxin 这里不需要加上保费
			      e.shipper_pay_much_truck_owner = parseFloat((e.this_pay_freight_driver_max + e.this_pay_freight_truck_max)- Number(e.shipper_pay_much_driver)).toFixed(2)
			  }
			  t1=parseFloat(e.shipper_pay_much_driver)+parseFloat(e.shipper_pay_much_truck_owner);
			  e.shipper_pay_much=t1.toFixed(2);
			  this.get_this_pay_info(e);
			},
			//自定义
			driver_custom(type){
				this.$prompt('请输入给'+type+ '金额', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					}).then(({ value }) => {
							this.need_pay_list.forEach(item=>{
								if(Number(value)>Number(item.freight_total)){
									this.$message({
										type: 'info',
										message: '输入运费大于运单总金额，请先修改运费'
									});
								}
								if(type=='司机'){
	                               item.shipper_pay_much_driver =value
								   item.shipper_pay_much_truck_owner = Number(item.freight_total).toFixed(2)-Number(value).toFixed(2)
								}else if(type=='收款人'){
							       item.shipper_pay_much_truck_owner =value
								   item.shipper_pay_much_driver = Number(item.freight_total).toFixed(2)-Number(value).toFixed(2)
						        }
							
							})

					}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消输入'
					});       
				});
			},
			//给司机
			this_pay_driver_input(item){
				
				//数据金额格式化
				item.shipper_pay_much_driver = item.shipper_pay_much_driver.match(/\d*\.{0,11}\d{0,2}/)[0]|| null
				if(!item.shipper_pay_much_driver){
					item.shipper_pay_much_driver =0
				}
				if(Number(item.shipper_pay_much_driver)<0){
					item.shipper_pay_much_driver = 0;
					this.this_pay_driver_input(item);
					return
				}
				
				//最大值判断
				if(Number(item.shipper_pay_much_driver)+Number(item.system_profit) > Number(item.this_pay_freight)){
					console.log("driver_payed_edit_step1")
					//lvxin  能进入这里，代表给司机的金额超过了最大值，应该用两个max加起来
					// item.shipper_pay_much_driver =  item.this_pay_freight-item.shipper_pay_system_profit
					item.shipper_pay_much_driver =  item.this_pay_freight_driver_max+item.this_pay_freight_truck_max
					item.shipper_pay_much_truck_owner =  0
					
					//自动计算服务费
					// this.get_this_pay_info(item);
					
					// returns
				}
				
				let t1=parseFloat(item.shipper_pay_much_driver)+parseFloat(item.shipper_pay_much_truck_owner);
				let t2=parseFloat(item.this_pay_freight_driver_max)+parseFloat(item.this_pay_freight_truck_max);
				
				if(Number(item.shipper_pay_much_driver)+Number(item.shipper_pay_much_truck_owner)+Number(item.system_profit)>item.this_pay_freight){
					console.log("driver_payed_edit_step2")
				    // item.shipper_pay_much_truck_owner = parseFloat((item.this_pay_freight_driver_max + item.this_pay_freight_truck_max+item.system_profit)- Number(item.shipper_pay_much_driver)).toFixed(2)
					//lvxin 这里不需要加上保费
				    item.shipper_pay_much_truck_owner = parseFloat((item.this_pay_freight_driver_max + item.this_pay_freight_truck_max)- Number(item.shipper_pay_much_driver)).toFixed(2)

				}
			
				//自动计算服务费
				t1=parseFloat(item.shipper_pay_much_driver)+parseFloat(item.shipper_pay_much_truck_owner);
				item.shipper_pay_much=t1.toFixed(2);
				// item.this_pay_freight=t1.toFixed(2);
				
				
				
				this.get_this_pay_info(item);
				
				
			},
			//给收款人
			this_pay_truck_owner_input(item){
				console.log("修改给收款人金额")
				item.shipper_pay_much_truck_owner = item.shipper_pay_much_truck_owner.match(/\d*\.{0,11}\d{0,2}/)[0]|| null
				if(!item.shipper_pay_much_truck_owner){
					item.shipper_pay_much_truck_owner =0
				}
				if(Number(item.shipper_pay_much_truck_owner)<0){
					// item.shipper_pay_much_driver =  item.this_pay_freight
					item.shipper_pay_much_truck_owner = 0
					this.this_pay_truck_owner_input(item);
					return
				}

				// item.shipper_pay_much_truck_owner = item.shipper_pay_much_truck_owner.replace(/[^\d.]/g, "");
				if(Number(item.shipper_pay_much_truck_owner)+Number(item.system_profit) > Number(item.this_pay_freight)){
					item.shipper_pay_much_truck_owner =  item.this_pay_freight_driver_max+item.this_pay_freight_truck_max;//item.this_pay_freight-item.shipper_pay_system_profit
					item.shipper_pay_much_driver =  0
					// this.$my.other.msg({
					// 	type:'warning',
					// 	str:"本次支付金额超过剩余可支付金额, 如果确实需要, 建议先'修改运费'"
					// });
					//自动计算服务费
					// this.get_this_pay_info(item);
					// return

				}

				let t1=parseFloat(item.shipper_pay_much_driver)+parseFloat(item.shipper_pay_much_truck_owner);
				let t2=parseFloat(item.this_pay_freight_driver_max)+parseFloat(item.this_pay_freight_truck_max);
				if(Number(item.shipper_pay_much_driver)+Number(item.shipper_pay_much_truck_owner)>item.this_pay_freight){
					// console.log(item.this_pay_freight_driver_max,item.this_pay_freight_truck_max)
				    item.shipper_pay_much_driver = parseFloat((item.this_pay_freight_driver_max + item.this_pay_freight_truck_max)- Number(item.shipper_pay_much_truck_owner)).toFixed(2)
					console.log(item.shipper_pay_much_driver)
				}
			
				//自动计算服务费
				t1=parseFloat(item.shipper_pay_much_driver)+parseFloat(item.shipper_pay_much_truck_owner);
				item.shipper_pay_much=t1.toFixed(2);
				// item.this_pay_freight=t1.toFixed(2);
				this.get_this_pay_info(item);
				
			},
			//车队长清空/满配
			truck_owner_money_empty(type){
				this.truck_owner_pay_total_ishow = !this.truck_owner_pay_total_ishow
				if(type =='empty'){
					this.need_pay_list.forEach(item=>{
						item.shipper_pay_much_truck_owner = 0
						//自动计算服务费
						item.shipper_pay_much=parseFloat(item.shipper_pay_much_driver);
						this.get_this_pay_info(item);
					})
				}else{
					this.need_pay_list.forEach(item=>{
						item.shipper_pay_much_truck_owner = item.this_pay_freight-item.system_profit //lvxin 20240414
						//剩余可支付金额上限判断
						// let max=parseFloat(item.this_pay_freight)-parseFloat(item.shipper_pay_much_driver);
						// item.shipper_pay_much_truck_owner=max;
						item.shipper_pay_much_driver = 0
						// if(item.shipper_pay_much_truck_owner>max){
							
						// }
						//自动计算服务费
						item.shipper_pay_much=parseFloat(item.shipper_pay_much_driver)+parseFloat(item.shipper_pay_much_truck_owner);
						this.get_this_pay_info(item);
					})
				}

			},
			//司机清空/满配
			driver_money_empty(type){
				this.driver_pay_total_ishow = !this.driver_pay_total_ishow
				if(type =='empty'){
					this.need_pay_list.forEach(item=>{
						item.shipper_pay_much_driver = 0
						item.shipper_pay_much=parseFloat(item.shipper_pay_much_truck_owner);
						this.get_this_pay_info(item);
					})
				}else{
					
					this.need_pay_list.forEach(item=>{
						item.shipper_pay_much_driver = item.this_pay_freight-item.system_profit//lvxin 20240414
						
						//剩余可支付金额上限判断
						// let max=parseFloat(item.this_pay_freight)-parseFloat(item.shipper_pay_much_truck_owner);
						// item.shipper_pay_much_driver=max;
						item.shipper_pay_much_truck_owner = 0
						// if(item.shipper_pay_much_driver>max){
							
						// }
						item.shipper_pay_much=parseFloat(item.shipper_pay_much_driver)+parseFloat(item.shipper_pay_much_truck_owner);
						this.get_this_pay_info(item);
					})
				}
			},
			logo_close(){
				let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => {
					loadingInstance.close();
				});
			},


			//提交
			sub(n){
				Loading.service(this.options);
				// 没数据了
				if(!this.need_pay_list[n]){
					if(this.fail_pay_list.length>0){
						this.need_pay_list = this.fail_pay_list
						this.fail_pay_list = []
						this.pay_pwd='';
						this.logo_close()
						return
					}
					this.$my.other.msg({
						type:'success',
						str:"操作成功"
					});
					this.num = 0
					this.this_is_show=false;
					this.need_pay_list=[];
					this.pay_pwd='';
					this.$emit("close");
					this.logo_close()
					return
				}
				// if(!this.pay_pwd.trim() && this.this_act_type==1){
				// 	this.$my.other.msg({
				// 		type:'warning',
				// 		str:"缺少支付密码"
				// 	});
				// 	return;
				// }

				//搓本次支付列表
				let pay_creat_list=[];
				let pay_creat_item = this.need_pay_list[n]
				
				// for(var pay_creat_item of this.need_pay_list[n]){

					// if(pay_creat_item.shipper_pay_much==0 && this.pay_service_status !=1){
					// 	continue
					// }
					var  pay_action=''
					switch(pay_creat_item.mark){
						case '预付款':pay_action=1;break;
						case '到货付':pay_action=2;break;
						case '回单付':pay_action=3;break;
						case '其他':pay_action=4;break;
					}
					var shipper_pay_much = Number(pay_creat_item.shipper_pay_much_driver) + Number(pay_creat_item.shipper_pay_much_truck_owner)
					pay_creat_list.push({
						truck_tord_num:pay_creat_item.truck_tord_num,
						this_pay_freight:shipper_pay_much,
						this_pay_type:pay_creat_item.this_pay_type,
						shipper_pay_much_driver:pay_creat_item.shipper_pay_much_driver,
						shipper_pay_much_truck_owner:pay_creat_item.shipper_pay_much_truck_owner,
						shipper_pay_system_profit:pay_creat_item.shipper_pay_system_profit,
						mark:pay_creat_item.mark,
						pay_action
					});
				// }
				if(pay_creat_list.length==0){
					this.$my.other.msg({
						type:'warning',
						str:"没有可用的支付申请,或者所选的支付申请全部为0"
					});
					this.logo_close()
					return;
				}
				if(pay_creat_item.shipper_pay_much_driver >0 && this.driver_state== 0){
					this.driver_state =1
					pay_creat_list[0].shipper_pay_much_truck_owner =''
					pay_creat_list[0].shipper_pay_system_profit =''
					pay_creat_list[0].this_pay_freight = pay_creat_item.shipper_pay_much_driver
				}
				else if(pay_creat_item.shipper_pay_much_truck_owner >0 &&this.truck_owner_state==0){
					this.truck_owner_state =1
					pay_creat_list[0].shipper_pay_much_driver =''
					pay_creat_list[0].shipper_pay_system_profit =''
					pay_creat_list[0].this_pay_freight = pay_creat_item.shipper_pay_much_truck_owner
				}
				else if(pay_creat_item.shipper_pay_system_profit>0 &&this.profit_state==0){
					this.profit_state =1
					pay_creat_list[0].shipper_pay_much_truck_owner =''
					pay_creat_list[0].shipper_pay_much_driver =''
					pay_creat_list[0].this_pay_freight = pay_creat_item.shipper_pay_system_profit
				}
				if(this.driver_state==1){
					//司机收款
				   this.sub_pay_info(pay_creat_list,n)
				   this.driver_state=2
				}else if(this.truck_owner_state==1){
					//车队长收款
					this.sub_pay_info(pay_creat_list,n)
					this.truck_owner_state=2
				}else if(this.profit_state ==1){
					//公司利润
					this.sub_pay_info(pay_creat_list,n)
					this.profit_state=2
				}else if(pay_creat_item.shipper_pay_system_profit==0 &&pay_creat_item.shipper_pay_much_truck_owner==0&& pay_creat_item.shipper_pay_much_driver ==0 && pay_creat_item.this_pay_service_charge >0 && this.service_state==0){
					//服务费
					this.service_state =2
					this.sub_pay_info(pay_creat_list,n)
				}
				else{
					//三次传完 传下一条
					this.driver_state =0
					this.service_state =0
					this.truck_owner_state =0
					this.profit_state =0
					// this.need_pay_list.splice(n,1)
					n++
					this.sub(n)
				}
			},
			//提交
			sub_pay_info(pay_creat_list,n){
				
				let tips = ''
				if(this.this_act_type == '2'){
					tips = '当前请款'
				}else{
					tips = '当前支付'
				}
				this.$my.other.msg({
					type:'success',
					str:tips+this.nums+'条'
				});

				//调接口
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_pay_creat_by_shipper',
						this_act_type:this.this_act_type,
						pay_pwd:this.pay_pwd,
						pay_creat_list:pay_creat_list,
						pay_service_type:this.pay_service_type
					},
					callback:(data)=>{
						if(data.code ==0){
							this.nums++
							// n++
							this.sub(n)
						}else{
							this.fail_pay_list.push(this.need_pay_list[n])
							this.$my.other.msg({
								type:'info',
								str:data.error_info
							});
							this.driver_state =0
							this.service_state =0
							this.truck_owner_state =0
							this.profit_state =0
							n++
							this.sub(n)
							
							
						}
					}
				});

			},
			//计算本次支付信息
			get_this_pay_info(item){
				if(item.this_pay_freight ==item.shipper_pay_much){
					item.mark ="到货付"
				}
			    if(item.this_pay_freight >item.shipper_pay_much && item.payed_times == 0){
					item.mark ="预付款"
				}
				if(item.this_pay_freight >item.shipper_pay_much && item.payed_times == 1){
					item.mark ="回单付"
				}
				if(item.this_pay_freight >item.shipper_pay_much && item.payed_times == 2){
					item.mark ="其他"
				}

				if(item.shipper_pay_much>item.this_pay_freight){
					this.$my.other.msg({
						type:'warning',
						str:`运单'${item.truck_tord_num}'支付金额超过最大可支付金额`
					});
					return;
				}

				//去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'get_tord_pay_info_by_shipper',
						truck_tord_num:item.truck_tord_num,
						this_pay_freight:item.shipper_pay_much,
						this_pay_type:item.this_pay_type,
						pay_service_type:this.pay_service_type,
						shipper_pay_system_profit:item.shipper_pay_system_profit 
					},
					callback:(data)=>{
						
						console.log(data)
						
						item.this_pay_service_charge=data.this_service_charge

						//统计本次支付总金额
						this.this_pay_total_calc()
					}
				})
			},

			//计算本次需付
			this_payed_calc(){

				//取出运单编号
				let truck_tord_nums=[];
				let need_pay_list=[];
				for(var tord_item of this.tord_list){
					truck_tord_nums.push(tord_item.truck_tord_num)
					need_pay_list.push({
						truck_tord_num:tord_item.truck_tord_num,
						case_addr:`${tord_item.case_city}${tord_item.case_county}`,
						aim_addr:`${tord_item.aim_city}${tord_item.aim_county}`,
						start_time_text:tord_item.start_time_text,
						arrived_time_text:tord_item.arrived_time_text,
						truck_owner:`${tord_item.truck_owner_info.name}/${tord_item.truck_owner_info.tel}`,
						driver:`${tord_item.driver_info.name}/${tord_item.driver_info.tel}`,
						truck:tord_item.truck_plate_num,
						payed_times:tord_item.payed_list.length,
						freight_total:tord_item.cost_info.freight_total,
					});
				}

				//没有需支付的运单
				if(truck_tord_nums.length==0){
					this.$my.other.msg({
						type:'warning',
						str:"没有需支付的运单"
					});
					this.$emit("close");
					return;
				}
				
				//打开显示
				this.this_is_show=true

				//获取待支付记录
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'get_tord_pay_batch_info_by_shipper',
						truck_tord_nums:truck_tord_nums,
						num:this.num,
						need_payed_list:1
					},
					callback:(surplus_pay)=>{
						//置入支付数据
						for(var item of need_pay_list){
							item.this_pay_freight=surplus_pay[item.truck_tord_num].this_pay_freight
							item.this_service_charge=surplus_pay[item.truck_tord_num].this_service_charge
							item.this_shipper_need_pay=surplus_pay[item.truck_tord_num].this_shipper_need_pay

							item.shipper_pay_much=surplus_pay[item.truck_tord_num].shipper_pay_surplus
							item.this_pay_service_charge=surplus_pay[item.truck_tord_num].this_service_charge
							item.this_pay_type='1'
							item.system_profit = Number(surplus_pay[item.truck_tord_num].cost_info.system_profit)
							switch(item.payed_times){
								case 0:item.mark="预付款";break;
								case 1:item.mark="到货付";break;
								case 2:item.mark="回单付";break;
								default :item.mark="其他";break;
							}
							if(item.this_pay_freight == item.shipper_pay_much){
								item.mark ="到货付"
							}

							//当前可以支付给司机和收款人的金额上限
							item.this_pay_freight_driver_max=parseFloat(surplus_pay[item.truck_tord_num].cost_info.give_driver_total);
							item.this_pay_freight_truck_max=parseFloat(surplus_pay[item.truck_tord_num].cost_info.give_truck_owner_total);
							item.shipper_pay_system_profit =  Number(surplus_pay[item.truck_tord_num].cost_info.system_profit)
							//根据已支付的金额调整上限
							for(var i in surplus_pay[item.truck_tord_num].payed_list){
								let one=surplus_pay[item.truck_tord_num].payed_list[i];
								if(one.pay_status==2 || one.pay_status==3){//2支付计划  3已支付
									item.this_pay_freight_driver_max-=parseFloat(one.this_settled_of_driver);
									item.this_pay_freight_truck_max-=parseFloat(one.this_settled_of_truck_owner);
									item.shipper_pay_system_profit-=parseFloat(one.this_settled_of_system);
								}
							}
							//当前支付的司机部分和收款人部分
							item.shipper_pay_much_truck_owner=item.this_pay_freight_truck_max.toFixed(2);
							item.shipper_pay_much_driver=item.this_pay_freight_driver_max.toFixed(2);
							
							//如果这次提交不是最后一笔 保障服务费的默认值要等于0-- lvxin   20240414
							if(item.shipper_pay_system_profit>0){
								if(item.this_pay_freight_driver_max>0 || item.this_pay_freight_truck_max>0){
									item.shipper_pay_system_profit=0;
								}
							}

						}
						this.max = need_pay_list.length
						//置入数据
						this.need_pay_list=need_pay_list;

						//统计本次支付总金额
						this.this_pay_total_calc()
					}
				});
			},

			//统计本次支付总金额
			this_pay_total_calc(){
				let this_pay_total=0;
				let shipper_pay_much = 0
				let this_pay_service_charge = 0
				for(var item of this.need_pay_list){
					if(item.shipper_pay_much&&item.this_pay_service_charge){
						this_pay_total+=parseFloat(item.shipper_pay_much)+parseFloat(item.this_pay_service_charge)
						shipper_pay_much+=parseFloat(item.shipper_pay_much)
						this_pay_service_charge+=parseFloat(item.this_pay_service_charge)
					}
				}
				this.this_pay_total=this_pay_total.toFixed(2);
				this.shipper_pay_much=shipper_pay_much.toFixed(2);
				this.this_pay_service_charge=this_pay_service_charge.toFixed(2);
			},

			//打开重置支付密码页面
			reset_pay_pwd_open(){
				this.reset_pay_pwd_is_show=true;
			},
			reset_pay_pwd_close(){//关闭重置支付密码页面
				this.reset_pay_pwd_is_show=false;
			},
		}
	}
</script>
<style lang="scss" scoped>
    .operation{
		// color: rgb(18, 208, 221);
		margin-left: 5px;
	}
	.el-dialog-s{
		z-index: 11;
	}
	.under_tab{
		position: relative;
		height: 40px;
		border: 1px solid #eee;
		border-width: 0 1px 1px 1px;
	}
	.this_pay_total{
		position: absolute;
		right: 0px;
		width: 539px;
		border-left: 1px solid #eee;
		height: 40px;
		line-height: 40px;
		padding-left: 10px;
	}

	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	
	}
	.el_inner_width{
		width:100%;
	}
	.payee{
		height: 30px;
		width: 30px;
		font-size: 20px;
		text-align: center;
		margin-top: 5px;
		border-radius: 5px;
		color:rgb(0, 0, 0);
		.shou{
			height: 30px;
			width: 30px;
			margin-top: 4px;
		}
	}
	.data{
		margin-top: 5px;
		margin-left: 10px;
	}
	.red{
		background-color: rgb(154,239,178);
	
	}
	.green{
		background-color: rgb(253,113,129);

	}
</style>